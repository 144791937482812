import { INavLinkGroup } from 'office-ui-fabric-react/lib/Nav';
import { reduxStore } from '../state';
import { logout } from '../state/account/actions';

export const menu: INavLinkGroup[] = [
    {
        name: 'Správa stránky',
        links: [
            {
                key: 'projects',
                name: 'Projekty',
                url: '/projects',

            },
            {
                key: 'publications',
                name: 'Publikace',
                url: '/publications',
            },
            {
                key: 'contacts',
                name: 'Kontakty',
                url: '/contacts',
            },
        ],
    },
    {
        name: 'Uživatel',
        collapseByDefault: true,
        links: [
            {
                key: 'logout',
                name: 'Odhlásit se',
                url: '#logout',
                onClick: (e) => {
                    e?.preventDefault();
                    reduxStore.dispatch(logout as any)
                }
            }
        ],
    },
];