import styled from 'styled-components';
import LoginBackground from '../../static/login_bcg.jpg';

export const Wrapper = styled.div`
    width:100%;
    height:100vh;
    display:flex;
    justify-content:center;
    align-items:center;
    background-image:url(${LoginBackground});
    background-size:cover;
    position:fixed;
    top:0;
    left:0;
`;

export const LoginWrapper = styled.div`
    box-sizing: border-box;
    overflow:hidden;
    position:relative;
    width:350px;
    max-width:100%;
    background-color:white;
    height:550px;
    max-height:100%;
    border-radius:5px;
    box-shadow: 0 0 25px 0 rgba(0,0,0,.5);
    &>* {
        box-sizing: border-box;
        display:flex;
        flex-direction:column;
        position:absolute;
        padding:50px 25px;
    }
    & .logo{
        width:50%;
        align-self:center;
        margin-bottom:50px;
    }
    & .loading{
        position:absolute;
        top:0;
        left:0;
        width:100%;
        height:100%;
        background-color:rgba(255,255,255,.8);
        display:flex;
        justify-content:center;
        align-items:center;
        z-index:10;
    }
    & .error {
        display:block;
        font-size:0.8em;
        color:red;
        margin-top:15px;
        width:100%;
        text-align:center;
        clear:both;
    }
    & .enter {
        opacity: 0;
        transform:translate(0,-250px);
      }
    & .enter-active {
        opacity: 1;
        transform:translate(0,0);
        transition: all 350ms cubic-bezier(0.390, 0.575, 0.565, 1.000);
        transition-delay:350ms;
      }
    & .exit {
        transform:translate(0,0);
        opacity: 1;
      }
    & .exit-active {
        opacity: 0;
        transform:translate(0,250px);
        transition: all 350ms cubic-bezier(0.895, 0.030, 0.685, 0.220);
    }
`;