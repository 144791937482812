import axios from 'axios';
import {SERVER_URI} from '../config'
export const Axios = axios.create({
    baseURL: SERVER_URI,
    // headers:{
    //     "Access-Control-Allow-Origin":"*"
    // },
    withCredentials:true
});

