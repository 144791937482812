import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { INavStyles, INavLinkGroup } from 'office-ui-fabric-react/lib/Nav';
import { darkTheme } from '../../theme';
import logo from '../../static/logo_white.png';
import {SideNav} from '../SideNav';

const Wrapper = styled.div`
    width:100vw;
    display:flex;
    box-sizing:border-box;
`;

const SideNavWrapper = styled.div`
    width:300px;
    min-width:300px;
    min-height:100%;
    overflow-x:hidden;
    overflow-y:auto;
    background-color:rgba(33,120,160,.6);
    box-sizing:border-box;
    & .logo{
        display:flex;
        width:100%:
        justify-content:center;
        align-items:center;
        padding:30px 0px;
        &>img {
            margin:0 auto;
            width:50%;
        }
    }
    & *:hover, *:active {
        color:white;
    }
`;

const Content = styled.div`
    background-color:white;
    min-height:100vh;
    box-shadow:0 0 25px 0 rgba(0,0,0,.25);
    padding:35px;
    box-sizing:border-box;
    width:100%;
    max-width:100%;
    overflow-x:hidden;
`;

const navStyles: Partial<INavStyles> = { root: { width: '100%' } };
interface Props {
    menu: INavLinkGroup[];
}
export const Page: FunctionComponent<Props> = ({ children, menu }) => {
    return <Wrapper>
        <SideNavWrapper>
            <div className='logo'>
                <img src={logo} alt="Logo" />
            </div>
            <SideNav
                styles={navStyles}
                theme={darkTheme}
                groups={menu} />
        </SideNavWrapper>
        <Content>
            {children}
        </Content>
    </Wrapper>
}
