import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { Separator } from 'office-ui-fabric-react/lib/Separator';

const Box = styled.div`
    padding:50px;
`;

interface ContainerProps {
    width?:string | number;
    columns:number;
}
export const FormContainer = styled.div`
    display:flex;
    width:${({width}:ContainerProps)=>width || '100%'};
    flex-wrap: wrap;
    &>* {
        flex: 1 0 calc(100% / ${({columns}:ContainerProps)=>columns} - 10%);
    }
`;

interface Props {
    name:string;
}
export const FormBox:FunctionComponent<Props> = ({children,name})=>{
    return <Box>
        <strong>{name}</strong>
        <Separator />
        {children}
    </Box>
}