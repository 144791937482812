import React, { Component, Ref } from 'react';
import isFunction from 'lodash/isFunction';
import { Messenger } from '../core/Messenger';

type Provider = {
    selectFile: (e: React.MouseEvent<HTMLInputElement>) => void;
}
type Props = {
    handleDrop: (files: FileList) => any;
    disabled?: boolean;
    dropDisabled?: boolean;
    children: (props: Provider) => any;
    accepts?: string[];
    fileSize?: number;
}
type State = {
    drag: boolean;
}
export class DragNDrop extends Component<Props, State> {
    state: State = {
        drag: false
    }
    private fileRef = React.createRef<HTMLInputElement>();
    private dragCounter: number = 0;
    private dropRef = React.createRef<HTMLDivElement>();
    private messenger = new Messenger()
    private handleDrag = (e: DragEvent) => { }
    private handleDragIn = (e: DragEvent) => {
        this.dragCounter++;
        if (e.dataTransfer?.items && e.dataTransfer?.items.length > 0) {
            this.setState({ drag: true });
        }
    }
    private handleDragOut = (e: DragEvent) => {
        this.dragCounter--;
        if (this.dragCounter === 0) {
            this.setState({ drag: false });
        }
    }
    private validate(files: FileList): boolean {
        const { accepts, fileSize } = this.props;
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (accepts) {
                if (!accepts.includes(file.type)) {
                    this.messenger.pushNotification('error', `Nepodporovaný formát souboru. Prosím nahrajte jeden z těchto: ${accepts.join(', ')}`)
                    return false;
                }
            }
            if(fileSize) {
                const size = file.size;
                if(size > fileSize){
                    this.messenger.pushNotification('error', `Soubor je příliš velký. Maximální velikost souboru je ${fileSize/1024} kB.`)
                    return false;
                }
            }
        }
        return true;
    }
    private handleDrop = (e: DragEvent) => {
        this.setState({ drag: false });
        const files = e.dataTransfer?.files;
        if (files && files.length > 0 && this.validate(files)) {
            this.props.handleDrop(files);
        }
        e.dataTransfer?.clearData();
        this.dragCounter = 0;
    }

    private click = (e: React.MouseEvent) => {
        const input = this.fileRef?.current;
        if (input) {
            input.click()
        }
    }
    private loadFileFromInput = (e: Event) => {
        e.preventDefault();
        const input = this.fileRef?.current;
        const files = input?.files;
        if (input && files && this.validate(files)) {
            this.props.handleDrop(files);
        }
    }
    componentDidMount() {
        this.addListeners();
    }
    componentWillUnmount() {
        this.removeListeners();
    }
    private addListeners(){
        /*const div = this.dropRef.current as HTMLDivElement;
        div.addEventListener('dragenter', this.handleDragIn)
        div.addEventListener('dragleave', this.handleDragOut)
        div.addEventListener('dragover', this.handleDrag)
        div.addEventListener('drop', this.handleDrop)*/
        const input = this.fileRef?.current;
        if (input) {
            input.addEventListener('change', this.loadFileFromInput)
        }
    }
    private removeListeners(){
        /*const div = this.dropRef.current as HTMLDivElement;
        div.removeEventListener('dragenter', this.handleDragIn)
        div.removeEventListener('dragleave', this.handleDragOut)
        div.removeEventListener('dragover', this.handleDrag)
        div.removeEventListener('drop', this.handleDrop)*/
        const input = this.fileRef?.current;
        if (input) {
            input.removeEventListener('change', this.loadFileFromInput)
        }
    }
    getActions(): Provider {
        return {
            selectFile: this.click
        }
    }
    render() {
        const { children, accepts, disabled} = this.props;
        const { drag } = this.state;
        return (
            <div
                style={{ position: 'relative' }}
                ref={this.dropRef}
            >
                {disabled && <div style={{
                            backgroundColor: 'rgba(255,255,255,.8)',
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            zIndex: 9999,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: 'grey',
                            fontSize: 18
                        }}>Nahrávám</div>}
                <input ref={this.fileRef} accept={accepts ? accepts.join(',') : undefined} type="file" multiple style={{ display: 'none' }} />
                {drag &&
                    <div
                        style={{
                            border: 'dashed grey 1px',
                            backgroundColor: 'rgba(255,255,255,.8)',
                            position: 'absolute',
                            top: 0,
                            bottom: 0,
                            left: 0,
                            right: 0,
                            zIndex: 9999,
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            color: 'grey',
                            fontSize: 18
                        }}
                    >
                        <div>Vložte obrázky sem</div>
                    </div>
                }
                {isFunction(children) ? children(this.getActions()) : children}
            </div>
        )
    }
}