import { MessageBarType } from 'office-ui-fabric-react';
import { ThunkAction } from 'redux-thunk';
export const ADD_NOTIFICATION = 'ADD_NOTF';
export const REMOVE_NOTIFICATION = 'R_NOTF';

export type Notification = {
    id:number
    type:keyof typeof MessageBarType;
    message:string;
};
export type NotificationAction = {
    type: typeof ADD_NOTIFICATION;
    payload: Notification
}
type RemoveAction = {
    type:typeof REMOVE_NOTIFICATION;
    id:number;
}
export const addNotification = (notification:Notification):NotificationActions => ({
    type: ADD_NOTIFICATION,
    payload: notification
});
export const removeNotification = (id:number):RemoveAction =>({
    type:REMOVE_NOTIFICATION,
    id
})

export const pushNotification = (notification:Omit<Notification,'id'>):ThunkAction<any,any,any,any> => {
    return (dispatch)=>{
        const id = Date.now();
        dispatch(addNotification({
            ...notification,
            id
        }));
        setTimeout(()=>{
            dispatch(removeNotification(id))
        },5000)
    }
}


export type NotificationActions = NotificationAction | RemoveAction;