import React, { FunctionComponent } from 'react';
import { connect } from 'react-redux';
import { ReduxState } from '../../state/state';
import { ThunkDispatch } from 'redux-thunk';
import styled from 'styled-components';
import { Persona, PersonaSize, PersonaPresence } from 'office-ui-fabric-react/lib/Persona';

type Props = {
    name?:string;
}
export const WelcomeWrapper = styled.div`
    width:100%;
    height:100%;
    display:flex;
    justify-content:center;
    align-items:center;
    & h2 {
        font-size:1.5em;
    }
    & span {
        padding-top:20px;
        font-size:1.2em
    }
`;
const WelcomeBase:FunctionComponent<Props & {dispatch:ThunkDispatch<any,any,any>}> = ({name,dispatch})=>{
    return <WelcomeWrapper>
        <h2>
            Vítejte
        </h2>
        <Persona initialsColor="#219ECA" text={name} size={PersonaSize.size56} hidePersonaDetails presence={PersonaPresence.online}/>
        <span>{name}</span>
    </WelcomeWrapper>
}

const mapStateToProps = (state:ReduxState)=>({
    name:state.account.name
})
export const Welcome = connect(mapStateToProps)(WelcomeBase);