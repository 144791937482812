import React, { FunctionComponent, Fragment } from 'react';
import { Publication } from './Publication';
import { Header } from '../Header';
import { onEnter } from '../../utils/keys';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { PrimaryButton, Link } from 'office-ui-fabric-react';
import { Multilingual } from '../../global.types';
import { FormBox, FormContainer } from '../FormBox';
import { Axios } from '../../core/axios';
import { withRouter } from 'react-router-dom';
import { AxiosResponse, AxiosError } from 'axios';
import { RouteComponentProps, Link as Redir } from 'react-router-dom'
import { Messenger } from '../../core/Messenger';
import { StatefulComponent } from '../../core/StatefulComponent';
import { Label } from 'office-ui-fabric-react/lib/Label';
import { AvatarEdit } from '../AvatarEdit';
import AvatarEditor from 'react-avatar-editor';
import { PublicationSchema } from './publication.schema';
interface Props extends RouteComponentProps<{ id?: string }> {
}

const RequiredTextField: FunctionComponent = (props) => <TextField required={true} {...props}></TextField>


interface State {
    globalDisable?: boolean;
    data?: Publication;
    loaded?: boolean;
    image?: File | string;
}

const defaultMiltilingual: Multilingual<string> = {
    cs: '',
    en: '',
    sk: ''
}

const defaultValues: Omit<Publication, '_id'> & Partial<Pick<Publication, '_id'>> = {
    _id: undefined,
    name: defaultMiltilingual,
    time: 0,
    img: '',
    src: ''
}
export class ChangePublicationBase extends StatefulComponent<Props, State> {

    messenger = new Messenger();
    state: State = {
        globalDisable: true
    }

    ref = React.createRef<AvatarEditor>();

    componentDidMount() {
        const { params: { id } } = this.props.match;
        if (id) {
            Axios.get('/publication/' + id).then(({ data }: AxiosResponse) => {
                this.setState({
                    data: data,
                    globalDisable: false,
                    loaded: true
                })
            }).catch(() => {
                this.setState({
                    loaded: true
                })
            })
        } else {
            this.setState({ globalDisable: false, loaded: true });
        }
    }
    send = (values: Publication) => {
        const { data } = this.state;
        this.setState({ globalDisable: true });
        const path = data ? '/publication/update' : '/publication/create';
        Axios.post(path, values).then((response) => {
            if (response.data?.status) {
                const id = response.data?.id;
                this.messenger.pushNotification('success', 'Publikace byla aktualizována');
                if (id) {
                    const { history } = this.props;
                    console.log('passing url')
                    history.push('/publications/update/' + id);
                }
            } else {
                this.messenger.pushNotification('error', 'Oops, něco se pokazilo');
            }
        }).finally(() => {
            this.setState({ globalDisable: false });
        }).catch((e: AxiosError) => {
            this.messenger.pushNotification('error', 'Oops, něco se pokazilo');
        })
    }
    render() {
        const { globalDisable, data, loaded } = this.state;
        const { params: { id } } = this.props.match;
        return <div>
            {loaded && <Formik validationSchema={PublicationSchema} onSubmit={this.send} initialValues={data || defaultValues as Publication}>
                {({
                    errors,
                    handleChange,
                    handleBlur,
                    submitForm,
                    setFieldValue,
                    values,
                    touched
                }) => (<div>
                    <Form>
                        <div style={{ width: '45%', padding: '0 25px 0 50px', boxSizing: 'border-box' }}>
                            {data && <Fragment>
                                <Header name="Nahrání úvodního obrázku" />
                                <Label required>Úvodní obrázek</Label>
                                <AvatarEdit
                                    uploadPath={"/publication/uploadCover/" + data._id}
                                    name="cover"
                                    data={data.img}
                                    size={[300, 420]}
                                    canvasSize={[300, 420]}
                                    field='img'
                                />
                            </Fragment>}
                            <Header name={data ? 'Úprava publikace' : 'Nová publikace'} />
                            <Field
                                type="number"
                                id="time"
                                disabled={globalDisable}
                                label="Rok"
                                name="time"
                                placeholder="Rok"
                                component={RequiredTextField}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onKeyDown={onEnter(submitForm)}
                                errorMessage={touched.time ? errors.time : undefined}
                                value={values.time}
                            />
                            <Field
                                type="string"
                                id="src"
                                disabled={globalDisable}
                                label="Odkaz"
                                name="src"
                                placeholder="Odkaz"
                                component={RequiredTextField}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onKeyDown={onEnter(submitForm)}
                                errorMessage={touched.src ? errors.src : undefined}
                                value={values.src}
                            />
                        </div>
                        <FormContainer columns={3}>
                            <Field
                                type='hidden'
                                id="_id"
                                disabled={globalDisable}
                                name="_id"
                                value={values._id}
                            />
                            <FormBox name="Český jazyk">
                                <Field
                                    id="name.cs"
                                    disabled={globalDisable}
                                    label="Název publikace"
                                    name="name.cs"
                                    placeholder="Název"
                                    component={RequiredTextField}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    onKeyDown={onEnter(submitForm)}
                                    errorMessage={touched.name?.cs ? errors.name?.cs : undefined}
                                    value={values.name?.cs}
                                />
                            </FormBox>
                            <FormBox name="Slovenština">
                                <Field
                                    id="name.sk"
                                    disabled={globalDisable}
                                    label="Názov publikácie"
                                    name="name.sk"
                                    placeholder="Názov"
                                    component={RequiredTextField}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    onKeyDown={onEnter(submitForm)}
                                    errorMessage={touched.name?.sk ? errors.name?.sk : undefined}
                                    value={values.name?.sk}
                                />
                            </FormBox>
                            <FormBox name="Angličtina">
                                <Field
                                    id="name.en"
                                    disabled={globalDisable}
                                    label="Publication name"
                                    name="name.en"
                                    placeholder="Name"
                                    component={RequiredTextField}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    onKeyDown={onEnter(submitForm)}
                                    errorMessage={touched.name?.en ? errors.name?.en : undefined}
                                    value={values.name?.en}
                                />
                            </FormBox>
                        </FormContainer>
                        <PrimaryButton disabled={globalDisable} style={{ marginLeft: 50 }} type="submit">Uložit</PrimaryButton>
                        <Redir to="/publications"><Link style={{ marginLeft: 25 }}>Zpět</Link></Redir>
                    </Form>
                </div>)}
            </Formik>}
            {!loaded && <div>Nahravam</div>}
        </div>
    }
}

class AddPublicationBase extends ChangePublicationBase { };
class UpdatePublicationBase extends ChangePublicationBase { }

export const AddPublication = withRouter(AddPublicationBase);
export const UpdatePublication = withRouter(UpdatePublicationBase);