import React, { FunctionComponent, Fragment } from 'react';
import { Project } from './Project';
import { Header } from '../Header';
import { onEnter } from '../../utils/keys';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { PrimaryButton, Link } from 'office-ui-fabric-react';
import { Multilingual } from '../../global.types';
import { FormBox, FormContainer } from '../FormBox';
import { Axios } from '../../core/axios';
import { withRouter } from 'react-router-dom';
import { AxiosResponse, AxiosError } from 'axios';
import { RouteComponentProps, Link as Redir } from 'react-router-dom'
import { Messenger } from '../../core/Messenger';
import { projectErrors } from './errors';
import { StatefulComponent } from '../../core/StatefulComponent';
import Logo from '../../static/logo.png';
import { Label } from 'office-ui-fabric-react/lib/Label';
import { AvatarEdit } from '../AvatarEdit';
import AvatarEditor from 'react-avatar-editor';
import { ProjectSchema } from './project.schema';
import { Spacer } from '../Spacer';
import { Gallery } from './gallery';
interface Props extends RouteComponentProps<{ id?: string }> {
}

const RequiredTextField: FunctionComponent = (props) => <TextField required={true} {...props}></TextField>
const Textarea: FunctionComponent = (props) => <TextField multiline={true} rows={10} {...props}></TextField>


interface State {
    globalDisable?: boolean;
    data?: Project;
    loaded?: boolean;
    image: File | string;
}

const defaultMiltilingual: Multilingual<string> = {
    cs: '',
    en: '',
    sk: ''
}

const defaultValues: Omit<Project, '_id'> & Partial<Pick<Project, '_id'>> = {
    _id: undefined,
    name: defaultMiltilingual,
    description: defaultMiltilingual,
    locationName: defaultMiltilingual,
    gps: '',
    time: 0,
    baseImg: '',
    imgs: []
}
export class ChangeProjectBase extends StatefulComponent<Props, State> {

    messenger = new Messenger(projectErrors);
    state: State = {
        globalDisable: true,
        image: Logo
    }

    ref = React.createRef<AvatarEditor>();

    componentDidMount() {
        const { params: { id } } = this.props.match;
        if (id) {
            Axios.get('/project/' + id).then(({ data }: AxiosResponse) => {
                this.setState({
                    data: data,
                    globalDisable: false,
                    loaded: true
                })
            }).catch(() => {
                this.setState({
                    loaded: true
                })
            })
        } else {
            this.setState({ globalDisable: false, loaded: true });
        }
    }
    send = (values: Project) => {
        const { data } = this.state;
        this.setState({ globalDisable: true });
        const path = data ? '/project/update' : '/project/create';
        Axios.post(path, values).then((response) => {
            const id = response.data?.id;
            this.messenger.pushNotification('success', 'Projekt byl aktualizován');
            if(id){
                const {history} = this.props;
                history.push('/projects/update/'+id);
            }
        }).finally(() => {
            this.setState({ globalDisable: false });
        }).catch((e: AxiosError) => {
            const code = e.response?.status || 0;
            this.messenger.pushNotification('error', code)
        })
    }
    render() {
        const { globalDisable, data, loaded } = this.state;
        const {params:{id}} = this.props.match;
        return <div>
            
            {loaded && <Formik validationSchema={ProjectSchema} onSubmit={this.send} initialValues={data || defaultValues as Project}>
                {({
                    errors,
                    handleChange,
                    handleBlur,
                    submitForm,
                    setFieldValue,
                    values,
                    touched
                }) => (<div>
                    <Form>
                        <div style={{ width: '45%', padding: '0 25px 0 50px', boxSizing: 'border-box' }}>
                            {data && <Fragment>
                                <Header name="Nahrání úvodního obrázku" />
                                    <Label required>Úvodní obrázek</Label>
                                    <AvatarEdit 
                                        uploadPath={"/project/uploadCover/"+data._id}
                                        name="cover"
                                        data={data.baseImg}
                                    />
                                </Fragment>}
                                <Header name={data ? 'Úprava projektu' : 'Nový projekt'} />
                            <Field
                                type="number"
                                id="time"
                                disabled={globalDisable}
                                label="Rok"
                                name="time"
                                placeholder="Rok"
                                component={RequiredTextField}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onKeyDown={onEnter(submitForm)}
                                errorMessage={touched.time ? errors.time : undefined}
                                value={values.time}
                            />
                            <Field
                                id="gps"
                                disabled={globalDisable}
                                label="Gps souřadnice"
                                name="gps"
                                placeholder="ex: 41,40338; 2,17403"
                                component={RequiredTextField}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onKeyDown={onEnter(submitForm)}
                                errorMessage={touched.gps ? errors.gps : undefined}
                                value={values.gps}
                            />
                        </div>
                        <FormContainer columns={3}>
                            <Field
                                type='hidden'
                                id="_id"
                                disabled={globalDisable}
                                name="_id"
                                value={values._id}
                            />
                            <FormBox name="Český jazyk">
                                <Field
                                    id="name.cs"
                                    disabled={globalDisable}
                                    label="Název projektu"
                                    name="name.cs"
                                    placeholder="Název"
                                    component={RequiredTextField}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    onKeyDown={onEnter(submitForm)}
                                    errorMessage={touched.name?.cs ? errors.name?.cs : undefined}
                                    value={values.name?.cs}
                                />
                                <Field
                                    id="locationName.cs"
                                    disabled={globalDisable}
                                    label="Lokalita"
                                    name="locationName.cs"
                                    placeholder="Lokalita"
                                    component={RequiredTextField}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    onKeyDown={onEnter(submitForm)}
                                    errorMessage={touched.locationName?.cs ? errors.locationName?.cs : undefined}
                                    value={values.locationName?.cs}
                                />
                                <Field
                                    id="description.cs"
                                    disabled={globalDisable}
                                    label="Popis projektu"
                                    name="description.cs"
                                    placeholder="Popis projektu"
                                    component={Textarea}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    errorMessage={touched.description?.cs ? errors.description?.cs : undefined}
                                    value={values.description?.cs}
                                />
                            </FormBox>
                            <FormBox name="Slovenština">
                                <Field
                                    id="name.sk"
                                    disabled={globalDisable}
                                    label="Názov projektu"
                                    name="name.sk"
                                    placeholder="Názov"
                                    component={RequiredTextField}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    onKeyDown={onEnter(submitForm)}
                                    errorMessage={touched.name?.sk ? errors.name?.sk : undefined}
                                    value={values.name?.sk}
                                />
                                <Field
                                    id="locationName.sk"
                                    disabled={globalDisable}
                                    label="Lokácie"
                                    name="locationName.sk"
                                    placeholder="Lokácie"
                                    component={RequiredTextField}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    onKeyDown={onEnter(submitForm)}
                                    errorMessage={touched.locationName?.sk ? errors.locationName?.sk : undefined}
                                    value={values.locationName?.sk}
                                />
                                <Field
                                    id="description.sk"
                                    disabled={globalDisable}
                                    label="Popis projektu"
                                    name="description.sk"
                                    placeholder="Popis projektu"
                                    component={Textarea}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    errorMessage={touched.description?.sk ? errors.description?.sk : undefined}
                                    value={values.description?.sk}
                                />
                            </FormBox>
                            <FormBox name="Angličtina">
                                <Field
                                    id="name.en"
                                    disabled={globalDisable}
                                    label="Project name"
                                    name="name.en"
                                    placeholder="Name"
                                    component={RequiredTextField}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    onKeyDown={onEnter(submitForm)}
                                    errorMessage={touched.name?.en ? errors.name?.en : undefined}
                                    value={values.name?.en}
                                />
                                <Field
                                    id="locationName.en"
                                    disabled={globalDisable}
                                    label="Location"
                                    name="locationName.en"
                                    placeholder="Location"
                                    component={RequiredTextField}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    onKeyDown={onEnter(submitForm)}
                                    errorMessage={touched.locationName?.en ? errors.locationName?.en : undefined}
                                    value={values.locationName?.en}
                                />
                                <Field
                                    id="description.en"
                                    disabled={globalDisable}
                                    label="Description"
                                    name="description.en"
                                    placeholder="Description"
                                    component={Textarea}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    errorMessage={touched.description?.en ? errors.description?.en : undefined}
                                    value={values.description?.en}
                                />
                            </FormBox>
                        </FormContainer>
                        <PrimaryButton disabled={globalDisable} style={{ marginLeft: 50 }} type="submit">Uložit</PrimaryButton>
                        <Redir to="/projects"><Link style={{ marginLeft: 25 }}>Zpět</Link></Redir>
                    </Form>
                </div>)}
            </Formik>}
            {data && <Fragment>
                <Spacer height="50px"/>
                <Gallery id={data._id} images={data.imgs}></Gallery>
            </Fragment>}
            {!loaded && <div>Nahravam</div>}
        </div>
    }
}

class AddProjectBase extends ChangeProjectBase{};
class UpdateProjectBase extends ChangeProjectBase{}

export const AddProject = withRouter(AddProjectBase);
export const UpdateProject = withRouter(UpdateProjectBase);