import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import * as serviceWorker from './serviceWorker';
import 'normalize.css';
import './theme';
import { RECAPTCHA_KEY } from './config';
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3';


const loadCaptcha = ()=>{
  return new Promise((resolve,reject)=>{
    //@ts-ignore
    if (!window.grecaptcha) {
      const script = document.createElement("script");
      script.src = `https://www.google.com/recaptcha/api.js?render=${RECAPTCHA_KEY}`;
      script.async = true;
      script.onload = () => {
          //@ts-ignore
          (grecaptcha as ReCaptchaInstance).ready(() => {
              resolve()
          })
      };
      script.onerror = ()=>{
        reject();
      }
      document.head.appendChild(script);
    }else{
      resolve()
    }
  })
}

//loadCaptcha().then(()=>{
  ReactDOM.render(
    <React.StrictMode>
      <GoogleReCaptchaProvider
        reCaptchaKey={RECAPTCHA_KEY}
      >
        <App />
      </GoogleReCaptchaProvider>
    </React.StrictMode>,
    document.getElementById('root')
  );
//});


// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
