import React, { Component } from 'react';
import styled from 'styled-components';
import { Login } from './Components/Login';
import { ResetPassword } from './Components/Login/reset'
import { Provider, connect } from 'react-redux';
import { reduxStore } from './state';
import LoginBackground from './static/login_bcg.jpg';
import { Page } from './Components/Page';
import { initializeIcons } from '@uifabric/icons';
import { menu } from './config/menu';
import { ReduxState } from './state/state';
import { Account, setAccount } from './state/account/actions';
import { Switch, Route, BrowserRouter as Router } from 'react-router-dom';
import { ProjectList, AddProject, UpdateProject } from './Components/Project';
import { Fabric } from 'office-ui-fabric-react';
import { Notifications } from './Components/Notifications';
import { Axios } from './core/axios';
import { AxiosError } from 'axios';
import { PublicationList, AddPublication, UpdatePublication } from './Components/Publications';
import { ChangeContact } from './Components/Contacts';

initializeIcons();

let csfrToken: string | undefined;

Axios.interceptors.response.use((response) => {
  const csfr = response.headers['x-csfr-token'];
  if (csfr) {
    csfrToken = csfr;
  }
  return response;
}, (error: AxiosError) => {
  const status = error.response?.status;
  if (status === 401) {
    csfrToken = undefined;
    reduxStore.dispatch(setAccount({
      authenticated: false,
      initialized:true
    }))
  }
  return Promise.reject(error);
});

Axios.interceptors.request.use((config) => {
  if (csfrToken) {
    config.headers['csfr-token'] = csfrToken;
  }
  return config;
})

const AppWrapper = styled.div`
  width:100%;
  min-height:100vh;
  font-family:sans-serif;
  background-image:url(${LoginBackground});
  background-size:cover;
  overflow-x:hidden;
  & h1 {
    color:#555;
    font-weight:100;
  }
`;

type Props = {
  account: Account;
}

class ContentBase extends Component<Props>{
  shouldComponentUpdate({ account: { authenticated: next } }: Props) {
    return next === true || next === false;
  }
  render() {
    const { account: { authenticated } } = this.props;
    return <AppWrapper>
      <Fabric>
        <Router>
          {!authenticated && <Switch>
            <Route path="/reset/:id" component={ResetPassword}></Route>
            <Route path="/">
              <Login />
            </Route>
          </Switch>}
          {authenticated && <Page menu={menu}>
            <Switch>
              <Route path="/projects/new" component={AddProject} />
              <Route path="/projects/update/:id" component={UpdateProject} />
              <Route path="/projects">
                <ProjectList />
              </Route>
              <Route path="/publications/new" component={AddPublication} />
              <Route path="/publications/update/:id" component={UpdatePublication} />
              <Route path="/publications">
                <PublicationList />
              </Route>
              <Route path="/contacts">
                <ChangeContact />
              </Route>
              <Route path="/">
                <ProjectList />
              </Route>
            </Switch>
          </Page>}
        </Router>
      </Fabric>
    </AppWrapper>
  }
}

const mapStateToProps = (state: ReduxState) => ({
  account: state.account
})

const Content = connect(mapStateToProps)(ContentBase);
class App extends Component {
  render() {
    return <Provider store={reduxStore}>
      <Content />
      <Notifications />
    </Provider>
  }
}

export default App;
