import * as Yup from 'yup';

export const LoginSchema = Yup.object().shape({
    username: Yup.string()
        .min(2, 'Příliš krátké uživatelské jméno')
        .max(120, 'Příliš dlouhé uživatelské jméno')
        .required('Uživatelské jmeno je povinná položka'),
    password: Yup.string()
        .min(6, 'Heslo je příliš krátké')
        .max(50, 'Heslo je příliš dlouhé')
        .required('Heslo je povinná položka'),
    recaptcha: Yup.string().required()
});

export const ResetSchema = Yup.object().shape({
    email: Yup.string()
        .min(2, 'Příliš krátký email')
        .max(120, 'Příliš dlouhý email')
        .required('Email je povinná položka'),
    password: Yup.string()
        .min(6, 'Heslo je příliš krátké')
        .max(50, 'Heslo je příliš dlouhé')
        .required('Heslo je povinná položka'),
    recaptcha: Yup.string().required()
});