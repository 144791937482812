import React from 'react';
import { Wrapper, LoginWrapper } from './wrappers';
import { LoginInit } from './init';
import { CSSTransition } from 'react-transition-group';
import { ReduxState } from '../../state/state';
import { connect } from 'react-redux';
import { Account } from '../../state/account/actions';
import { LoginForm } from './login';
import { Welcome } from './welcome';
import { ResetPassword } from './password';
import { StatefulComponent } from '../../core/StatefulComponent';

export type LoginCallback = (mod?: State['module']) => any;

interface Props {
    initialized:Account['initialized'];
    authenticated:Account['authenticated'];
}
interface State {
    module: 'password' | 'init' | 'login' | 'welcome';
    resetPassword?: boolean;
}
class LoginBase extends StatefulComponent<Props, State>{
    state: State = {
        module: 'init',
    };
    private getStateModule(initialized:boolean | undefined,authenticated:boolean | undefined):State['module']{
        if (initialized && !authenticated) {
            return 'login'
        } else if (authenticated) {
            return 'welcome'
        } else {
            return 'init'
        }
    }
    componentDidUpdate(prev: Readonly<Props>, prevState: State) {
        const { initialized,authenticated } = this.props;
        const {module} = this.state;
        const mod = module !== 'password'?this.getStateModule(initialized,authenticated):module
        if( mod !== module){
            this.updateStateValue('module', this.getStateModule(initialized,authenticated));
        }
    }

    /*shouldComponentUpdate(next:Props){
        const { account:{initialized,authenticated} } = next;
        const {module} = this.state;
        const newModule = this.getStateModule(initialized,authenticated);
        console.log(module,newModule);
        return module !== newModule;
    }*/
    callback = (module: State['module']) => {
        if(this.state.module !== module){
            this.updateStateValue('module', module);
        }
    }
    withTransition(Comp: JSX.Element, mod: State['module']) {
        const { module } = this.state;
        return <CSSTransition
            in={module === mod}
            timeout={700}
            unmountOnExit
        >
            {Comp}
        </CSSTransition>
    }
    render() {
        return <Wrapper>
            <LoginWrapper>
                {this.withTransition(<LoginInit />, 'init')}
                {this.withTransition(<LoginForm callback={this.callback} />, 'login')}
                {this.withTransition(<Welcome />, 'welcome')}
                {this.withTransition(<ResetPassword callback={this.callback} />, 'password')}
            </LoginWrapper>
        </Wrapper>
    }
}

const mapStateToProps = (state: ReduxState) => ({
    initialized:state.account.initialized,
    authenticated:state.account.authenticated
});

export const Login = connect(mapStateToProps)(LoginBase)

