import React, {Fragment} from 'react';
import { ReduxState } from '../state/state'
import { connect } from 'react-redux';
import { StatefulComponent } from '../core/StatefulComponent';
import { MessageBar,MessageBarType} from 'office-ui-fabric-react';


type Props = {
    notifications:ReduxState['notifications']
}
type State = {
    times:number[];
}
class NotificationsBase extends StatefulComponent<Props,State>{
    state = {
        times:[]
    }
    render() {
        const {notifications} = this.props;
        return <div style={{position:'fixed',bottom:0,left:0,width:'100%'}}>
            {notifications.map((value) => {
                return <MessageBar messageBarType={MessageBarType[value.type]} >
                    {value.message}
              </MessageBar>
            })}
        </div>;
    }
}

const mapStateToProps = (state: ReduxState) => ({
    notifications: state.notifications
})

export const Notifications = connect(mapStateToProps)(NotificationsBase);