import React, { Component } from 'react';
import { Wrapper, LoginWrapper } from './wrappers';
import { RouteComponentProps } from 'react-router-dom';
import Logo from '../../static/logo.png';
import { Formik, Form, Field } from 'formik';
import { ResetSchema } from './login.schema';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { Recaptcha } from '../Recaptcha';
import { PrimaryButton } from 'office-ui-fabric-react';
import { onEnter } from '../../utils/keys';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { StatefulComponent } from '../../core/StatefulComponent';
import { Axios } from '../../core/axios';
import { Messenger } from '../../core/Messenger';
import { Link } from 'react-router-dom';

interface Props extends RouteComponentProps<{ id: string }> { }
type State = {
    sent: boolean;
    globalDisable?: boolean;
    error?: string
}
export class ResetPassword extends StatefulComponent<Props, State> {
    state: State = {
        sent: false
    }

    messanger = new Messenger()
    reset = (values: { password: string, email: string }) => {
        const id = this.props.match.params.id;
        this.updateStateValue('globalDisable', true);
        Axios.post('/account/reset', {
            ...values,
            token: id
        }).then(() => {
            this.setState({
                ...this.state,
                ...{
                    sent: true,
                    globalDisable: false
                }
            })
        }).catch(() => {
            this.setState({
                ...this.state,
                ...{
                    error: 'Nepodařilo se nám heslo resetovat. Prosím zkuste to znovu',
                    globalDisable: false
                }
            })
        })
    }
    render() {
        const { sent, globalDisable, error } = this.state;
        return <Wrapper>
            <LoginWrapper>
                <div>
                    <img className="logo" src={Logo} alt="Logo" />
                    {!sent && <Formik
                        onSubmit={this.reset}
                        initialValues={{ password: '', email: '' }}
                        validationSchema={ResetSchema}
                    >
                        {({
                            errors,
                            handleChange,
                            handleBlur,
                            submitForm,
                            setFieldValue,
                            values,
                            touched
                        }) => (<div>
                            {globalDisable && <div className="loading"><Spinner size={SpinnerSize.large} label="Odesílám..." /></div>}
                            <Form>
                                <Field
                                    id="email"
                                    disabled={globalDisable}
                                    label="Email"
                                    name="email"
                                    placeholder="Email"
                                    component={TextField}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    onKeyDown={onEnter(submitForm)}
                                    autoFocus
                                    errorMessage={touched.email ? errors.email : undefined}
                                    value={values.email}
                                />
                                <Field
                                    id="password"
                                    type="password"
                                    disabled={globalDisable}
                                    label="Nové heslo"
                                    name="password"
                                    placeholder="Heslo"
                                    component={TextField}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    onKeyDown={onEnter(submitForm)}
                                    errorMessage={touched.password ? errors.password : undefined}
                                    value={values.password}
                                />
                                <Recaptcha setFieldValue={setFieldValue} elementID='login' />
                                <PrimaryButton disabled={globalDisable} type="submit" text="Odeslat" style={{ marginTop: 15 }} />
                                {error && <span className="error">{error}</span>}
                            </Form>
                        </div>)}
                    </Formik>}
                    {sent && <div style={{ width: "100%", textAlign: 'center' }}>
                        <h3>Heslo bylo změněno</h3>
                        <p>Nyní se můžete přihlásit</p>
                        <Link to="/" style={{ paddingTop: 24, fontSize: '1em' }}>Zpět na přihlášení</Link>
                    </div>}
                </div>
            </LoginWrapper>
        </Wrapper>
    }
}