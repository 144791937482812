import {Notification, pushNotification} from '../state/notifications/actions';
import {reduxStore} from '../state';
export type MessageObject ={
    default:string;
    [key:number]:string
}
/**
 * Manages internal messages and notifications
 */
export class Messenger {
    constructor(private messages?:MessageObject){}
    /**
     * Gets message based on a code. If code does not exists, it returns default value.
     * Otherwise returns empty string.
     * @param code Code of a message
     */
    getMessage(code?:number):string{
        const {messages} = this;
        if(messages){
            const field = code || 'default';
            return messages[field] || messages.default;
        }else{
            return '';
        }
        
    }
    /**
     * Adds notification to redux store.
     * @param type Corresponds to FluentUI message interface
     * @param message Content of the message or code of message
     */
    pushNotification(
        type:Notification['type'],
        msg:Notification['message'] | number
    ):void{
        const message:string = typeof msg === 'number'?this.getMessage(msg):msg;
        reduxStore.dispatch(pushNotification({type,message}));
    }
}