import React from 'react';
import Logo from '../../static/logo.png';
import {Recaptcha} from '../Recaptcha';
import { onEnter } from '../../utils/keys';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { PrimaryButton, Link } from 'office-ui-fabric-react';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import * as Yup from 'yup';
import { Axios } from '../../core/axios';
import { AxiosError } from 'axios';
import { StatefulComponent } from '../../core/StatefulComponent';
import {Messenger} from '../../core/Messenger';
import {resetErrors} from './errors';

interface State {
    globalDisable?:boolean;
    sent?:boolean;
    error?:string;
    
}
interface Props {
    callback:(data:any)=>any;
}
interface Values {
    email: string;
}

export const PasswordSchema = Yup.object().shape({
    email: Yup.string()
        .min(2, 'Příliš krátké uživatelské jméno')
        .max(120, 'Příliš dlouhé uživatelské jméno')
        .required('Uživatelské jmeno je povinná položka'),
    recaptcha: Yup.string().required()
});

export class ResetPassword extends StatefulComponent<Props,State> {
    state:State = {};
    errorManager = new Messenger(resetErrors);
    
    /**
     * Tries to reset password on the server
     * @param values 
     */
    resetPassword = (values:Values)=>{
        Axios.post('/account/send-reset',values).then(()=>{
            this.updateStateValue('sent',true);
        }).catch((e:AxiosError)=>{
            const code = e.response?.status;
            this.updateStateValue('error',this.errorManager.getMessage(code));
        })
    }

    render(){
        const { globalDisable,sent,error} = this.state;
        const { callback } = this.props;
        return <div>
            <img className="logo" src={Logo} alt="Logo" />
            {!sent && <Formik
                onSubmit={this.resetPassword}
                initialValues={{ email:'' }}
                validationSchema={PasswordSchema}
            >
                {({
                    errors,
                    handleChange,
                    handleBlur,
                    submitForm,
                    setFieldValue,
                    values,
                    touched
                }) => ( <div>
                            {globalDisable && <div className="loading"><Spinner size={SpinnerSize.large} label="Odesílám..." /></div>}
                            <Form>
                                <Field
                                    id="email"
                                    disabled={globalDisable}
                                    label="Email"
                                    name="email"
                                    placeholder="Email"
                                    component={TextField}
                                    onChange={handleChange}
                                    onBlur={handleBlur}
                                    onKeyDown={onEnter(submitForm)}
                                    autoFocus
                                    errorMessage={touched.email?errors.email:undefined}
                                    value={values.email}
                                />
                                <br />
                                <Recaptcha setFieldValue={setFieldValue} elementID='reset'/>
                                <PrimaryButton disabled={globalDisable} type="submit" text="Obnovit heslo" style={{ marginTop: 15 }} />
                                <Link onClick={()=>callback('login')} style={{ paddingTop: 24, float: 'right', fontSize: '.8em' }}>Zpět</Link>
                            </Form>
                            {error && <span className="error">{error}</span>}
                    </div>
                )}
            </Formik>}
            {sent && <div style={{width:"100%",textAlign:'center'}}>
                <h3>Email byl úspěšně odeslán.</h3>
                <p>Prosím přejděte do svého emailového klienta a pokračujte dle instrukcí.</p>
                <Link onClick={()=>callback('login')} style={{ paddingTop: 24, fontSize: '1em' }}>Zpět na přihlášení</Link>
            </div>}
        </div>
    }
}