import React, { Component, Fragment, FunctionComponent } from 'react';
import { Header } from '../Header';
import { Project } from './Project';
import { DragNDrop } from '../DragNDrop';
import { IconButton, DefaultButton, PrimaryButton } from 'office-ui-fabric-react';
import styled from 'styled-components';
import { Axios } from '../../core/axios';
import { Messenger } from '../../core/Messenger';
import { SortableContainer, SortableElement, SortEndHandler } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import { SERVER_URI } from '../../config';

type GalleryImageProps = {
    src: string;
    deletable?: boolean;
    callback?:(src:string)=>any;
}

type SortableProps = {
    items:string[];
    deletable:GalleryImageProps['deletable'];
    callback:GalleryImageProps['callback'];
}
const GalleryImage: FunctionComponent<GalleryImageProps> = ({ src, deletable, callback }) => {
    return <ImageListItem>
        {deletable && <IconButton onClick={()=>callback?callback(src):null} style={{ position: "absolute", top: 0, right: 0, backgroundColor: 'white', color: 'red' }} iconProps={{ iconName: 'delete' }} />}
        <img src={SERVER_URI + src} style={{ height: 150 }} alt={src} />
    </ImageListItem>
}

const SortableItem = SortableElement<GalleryImageProps>((props: GalleryImageProps) => <GalleryImage {...props} />);

const SortableList = SortableContainer(({ items, deletable, callback }: SortableProps) => {
    return (
        <ul>
            {items.map((value, index) => (
                <SortableItem key={value} index={index} disabled={deletable} deletable={deletable} src={value} callback={callback}/>
            ))}
        </ul>
    );
});

const ImgWrapper = styled.div`
    max-width:100%;
    overflow-x:hidden;
    box-sizing:border-box;
    & * {
        box-sizing:border-box;
    }
    &>ul{
        width:100%;
        max-width:100%;
        overflow-x:auto;
        list-style:none;
        display:block;
        white-space: nowrap;
        & li {
            display:inline-block;
            max-width:450px;
            width:auto;
            margin:10px;
        }
    }
`;

const ImageListItem = styled.li`
    position:relative;
    display:inline-block;
    max-width:450px;
    width:auto;
    margin:10px;
    list-style:none;
`;


type Props = {
    /**
     * List of initial images
     */
    images: Project['imgs'];
    id: string;
}
type State = {
    /**
     * List of current images
     */
    images: string[];
    reordered: boolean;
    disabled: boolean;
    deleting: boolean;
}
export class Gallery extends Component<Props, State> {
    state: State = {
        images: [],
        reordered: false,
        disabled: false,
        deleting: false
    }
    messenger = new Messenger();
    componentDidMount() {
        const { images } = this.props;
        this.setState({
            ...this.state,
            ...{
                images: images || []
            }
        });
    }
    private uploadFiles = async (files: FileList) => {
        const { id } = this.props;
        this.setState({
            ...this.state,
            ...{
                reordered: false,
                disabled: true
            }
        })
        for (let i = 0; i < files.length; i++) {
            const data = new FormData();
            const file = files[i];
            data.append('image', file);

            try {
                const result = await Axios.post<Project>('/project/image/upload/' + id, data, {
                    headers: {
                        'content-type': 'multipart/form-data'
                    }
                });
                const imgList = result.data.imgs;
                if (imgList) {
                    this.setState({
                        ...this.state,
                        ...{
                            images: imgList
                        }
                    })
                }
            } catch (e) {
                this.messenger.pushNotification('error', 'Oops. Něco se pokazilo. Prosím zkuste to znovu');
                //this.clearTempImages();
            }
        }
        this.setState({
            ...this.state,
            ...{
                disabled: false
            }
        })
    }
    private updateOrder: SortEndHandler = ({ oldIndex, newIndex }) => {
        this.setState(({ images, reordered }) => ({
            images: arrayMove(images, oldIndex, newIndex),
            reordered: true
        }));
    };
    saveOrder = async () => {
        const { images: imgs } = this.state;
        const { id } = this.props;
        this.setState({
            ...this.state,
            ...{
                disabled: true
            }
        })
        try {
            await Axios.post('/project/images/sort/' + id, { imgs })
            this.messenger.pushNotification('success', 'Pořadí bylo upraveno');
        } catch (e) {
            this.messenger.pushNotification('error', 'Oops. Něco se pokazilo. Prosím zkuste to znovu');
        }
        this.setState({
            ...this.state,
            ...{
                disabled: false,
                reordered: false
            }
        })
    }
    deleteContextToggle = () => {
        this.setState({
            ...this.state,
            ...{
                deleting: !this.state.deleting
            }
        })
    }
    deleteImage= async (src:string)=>{
        const {id} = this.props;
        this.setState({
            ...this.state,
            ...{
                disabled:true
            }
        })
        try{
            await Axios.post('/project/deleteImage/'+id,{src});
            this.setState({
                ...this.state,
                ...{
                    images:this.state.images.filter(item=>item!==src)
                }
            });
            this.messenger.pushNotification('success','Obrázek byl smazán')
        }catch(e){
            this.messenger.pushNotification('error','Něco se pokazilo')
        }
        this.setState({
            ...this.state,
            ...{
                disabled:false
            }
        })
    }
    render() {
        const { disabled, images, reordered, deleting } = this.state;
        const uploading = disabled;
        return <div>
            <DragNDrop disabled={uploading} accepts={["image/png", "image/jpeg", "image/gif"]} fileSize={800 * 1024} handleDrop={this.uploadFiles}>
                {(
                    {
                        selectFile
                    }
                ) => <Fragment>
                        <Header name="Galerie">
                            {reordered && <PrimaryButton disabled={uploading} onClick={this.saveOrder}>Uložit</PrimaryButton>}
                            <DefaultButton disabled={reordered || uploading || deleting} onClick={selectFile}>Nahrát obrázky</DefaultButton>
                            <DefaultButton disabled={reordered || uploading} onClick={this.deleteContextToggle}>{deleting ? 'Hotovo' : 'Smazat obrázky'}</DefaultButton>
                        </Header>
                        <ImgWrapper>
                            <SortableList callback={this.deleteImage} deletable={deleting} axis="x" items={images} onSortEnd={this.updateOrder} />
                        </ImgWrapper>
                    </Fragment>}
            </DragNDrop>
        </div>;
    }
}