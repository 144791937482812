import { Reducer } from "react";
import { AccountAction, ACCOUNT, Account} from "./actions";

export const accountReducer:Reducer<Account,AccountAction> = (state = {},action):Account=>{
    switch(action.type){
        case ACCOUNT:
            if(state !== action.payload){
                return action.payload
            }
            return state;
        default:
            return state;
    }
}