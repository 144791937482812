import * as Yup from 'yup';

const createMultiSchema = <T>(schema:Yup.Schema<T>)=>{
    return Yup.object({
        cs:schema,
        en:schema,
        sk:schema
    })
}

export const PublicationSchema = Yup.object().shape({
    name:createMultiSchema(Yup.string().required()),
    src:Yup.string().required(),
    time:Yup.number().required()
});