import React, { FunctionComponent } from 'react';
import { Contact } from './Contact';
import { Header } from '../Header';
import { onEnter } from '../../utils/keys';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { PrimaryButton } from 'office-ui-fabric-react';
import { Axios } from '../../core/axios';
import { AxiosResponse, AxiosError } from 'axios';
import { Messenger } from '../../core/Messenger';
import { StatefulComponent } from '../../core/StatefulComponent';
import { ContactSchema } from './contact.schema';

const RequiredTextField: FunctionComponent = (props) => <TextField required={true} {...props}></TextField>


interface State {
    globalDisable?: boolean;
    data: Contact[];
    loaded?: boolean;
    image?: File | string;
}


const defaultValues: Omit<Contact, '_id'> & Partial<Pick<Contact, '_id'>> = {
    _id: undefined,
    name: '',
    lang: 'cs',
    email: '',
    phone: '',
    address: ''
}
export class ChangeContact extends StatefulComponent<{}, State> {

    messenger = new Messenger();
    state: State = {
        globalDisable: true,
        data: []
    }

    componentDidMount() {
        Axios.get('/contacts').then(({ data }: AxiosResponse) => {
            this.setState({
                data: data,
                globalDisable: false,
                loaded: true
            })
        }).catch(() => {
            this.setState({
                loaded: true
            })
        })
    }
    send = (values: Contact) => {
        this.setState({ globalDisable: true });
        const path = '/contacts/update';
        Axios.post(path, values).then((response) => {
            if (response.data?.status) {
                this.messenger.pushNotification('success', 'Publikace byla aktualizována');
            } else {
                this.messenger.pushNotification('error', 'Oops, něco se pokazilo');
            }
        }).finally(() => {
            this.setState({ globalDisable: false });
        }).catch((e: AxiosError) => {
            this.messenger.pushNotification('error', 'Oops, něco se pokazilo');
        })
    }
    render() {
        const { globalDisable, data: value, loaded } = this.state;
        return <div>
            {loaded && value.map(data => <Formik validationSchema={ContactSchema} onSubmit={this.send} initialValues={data}>
                {({
                    errors,
                    handleChange,
                    handleBlur,
                    submitForm,
                    setFieldValue,
                    values,
                    touched
                }) => (<div>
                    <Form>
                        <div style={{ width: '45%', padding: '0 25px 0 50px', boxSizing: 'border-box' }}>
                            <Header name={`Úprava kontaktu ${data.lang}`} />
                            <Field
                                type="string"
                                id="name"
                                disabled={globalDisable}
                                label="Jméno"
                                name="name"
                                placeholder="Jméno"
                                component={RequiredTextField}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onKeyDown={onEnter(submitForm)}
                                errorMessage={touched.name ? errors.name : undefined}
                                value={values.name}
                            />
                            <Field
                                type="string"
                                id="address"
                                disabled={globalDisable}
                                label="Adresa"
                                name="address"
                                placeholder="Adresa"
                                component={RequiredTextField}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onKeyDown={onEnter(submitForm)}
                                errorMessage={touched.address ? errors.address : undefined}
                                value={values.address}
                            />
                            <Field
                                type="string"
                                id="phone"
                                disabled={globalDisable}
                                label="Telefon"
                                name="phone"
                                placeholder="Telefon"
                                component={RequiredTextField}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onKeyDown={onEnter(submitForm)}
                                errorMessage={touched.phone ? errors.phone : undefined}
                                value={values.phone}
                            />
                            <Field
                                type="string"
                                id="email"
                                disabled={globalDisable}
                                label="Email"
                                name="email"
                                placeholder="Email"
                                component={RequiredTextField}
                                onChange={handleChange}
                                onBlur={handleBlur}
                                onKeyDown={onEnter(submitForm)}
                                errorMessage={touched.email ? errors.email : undefined}
                                value={values.email}
                            />
                        </div>
                        <br />
                        <PrimaryButton onClick={() => setFieldValue('lang', data.lang)} disabled={globalDisable} style={{ marginLeft: 50 }} type="submit">Uložit</PrimaryButton>
                    </Form>
                </div>)}
            </Formik>)}
            {!loaded && <div>Nahravam</div>}
        </div>
    }
}