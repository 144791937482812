import { Reducer } from "react";
import { Notification, ADD_NOTIFICATION, REMOVE_NOTIFICATION, NotificationActions} from "./actions";
import remove from 'lodash/remove';

export const notificationReducer:Reducer<Notification[],NotificationActions> = (state = [],action):Notification[]=>{ 
    switch(action.type){
        case ADD_NOTIFICATION:
            return [
                ...state,
                action.payload
            ]
        case REMOVE_NOTIFICATION:
            return remove(state,(val)=>val.id !== action.id);
        default:
            return state;
    }
}