import React, { Component } from 'react';
import { Axios } from '../../core/axios';
import { IColumn,Selection} from 'office-ui-fabric-react/lib/index';
import {DataTable} from '../DataTable';
import {Header} from '../Header';
import {Project} from './Project';

interface ReducedProject {
    id:string;
    name:string;
    locationName:string;
    year:number;
    imgs?:number;
}


type State = {
    items: ReducedProject[];
    isDataLoaded: boolean;
    columns:IColumn[];
    selectionDetails?: string;
}
export class ProjectList extends Component<{},State> {
    state:State = {
        items: [],
        columns: [
            {
                key: 'name',
                name: 'Název projektu',
                fieldName: 'name',
                minWidth: 150,
                isRowHeader: true,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'location',
                name: 'Lokalita',
                fieldName: 'locationName',
                minWidth: 100,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'year',
                name: 'Rok',
                fieldName: 'year',
                minWidth: 50,
                isResizable: true,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'imgs',
                name: 'Počet obrázků',
                fieldName: 'imgs',
                minWidth: 100,
                data: 'string',
                isPadded: true,
            },
            {
                key: 'actions',
                name: 'Akce',
                fieldName: 'actions',
                minWidth: 100,
                data: 'string',
                isPadded: true,
            }
        ],
        isDataLoaded: false,
    };

    reducer(data:Project[]){
        const reducedData = data.reduce((buffer,value)=>{
            return [
                ...buffer,
                {
                    id:value._id,
                    name:value.name.cs,
                    locationName:value.locationName.cs,
                    year:value.time,
                    imgs:value.imgs?.length || 0
                }
            ]
        },[] as ReducedProject[]);
        this.setState({
            ...this.state,
            isDataLoaded:true,
            items:reducedData
        })
    }
    componentDidMount() {
        Axios.get('/projects').then(({ data }) => {
            this.reducer(data as Project[]);
        })
    }
    render() {
        const { items, columns, isDataLoaded } = this.state;
        return <div className="no-box-sizing">
            <Header name="Správa projektů"/>
            <DataTable 
                dataProps={{
                    items:items,
                    columns:columns
                }}
                selection={this._selection}
                dataLoaded={isDataLoaded}
                deletePath='/project/delete'
                path="/projects"
            />
        </div>
    }
    private _selection = new Selection({
        onSelectionChanged: () => {
            this.setState({
                selectionDetails: this._getSelectionDetails(),
              });
        }
    });
    private _getSelectionDetails(): string {
        const selectionCount = this._selection.getSelectedCount();
    
        switch (selectionCount) {
          case 0:
            return 'No items selected';
          case 1:
            return '1 item selected: ' + (this._selection.getSelection()[0] as ReducedProject).name;
          default:
            return `${selectionCount} items selected`;
        }
      }
}

export {AddProject,UpdateProject} from './change'