import { Component } from "react";
/**
 * Adds helper methods to a Component
 */
export abstract class StatefulComponent<P extends {},S extends {}> extends Component<P,S>{
    /**
     * Applications default state
     */
    public abstract state:S;
    /**
     * Updates one or many values of state
     * @param newState new partial state object
     */
    protected updateState(newState:Partial<S>):void{
        this.setState({
            ...this.state,
            ...newState
        })
    }
    /**
     * Updates one value in the state
     * @param key 
     * @param value 
     */
    protected updateStateValue<K extends keyof S>(key:K,value:S[K]){
        this.setState({
            ...this.state,
            [key]:value
        })
    }
}