import React from 'react';
import { withRouter } from 'react-router-dom';
import { Nav,INavProps, INavLink} from 'office-ui-fabric-react/lib/Nav';
export class SideNav extends React.Component<INavProps, any> {
    navWithRouter = withRouter(({ history }) => {
        const props = this.props;
        return (
            <Nav
                onLinkClick={
                    (ev?: React.MouseEvent<HTMLElement>, item?: INavLink) => {
                        ev?.preventDefault();
                        if (item && item.key) {
                            history.push('/'+item.key);
                        }
                    }}
                {...props}
            ></Nav>);
    });
    render() {
        return (
            <div>
                <this.navWithRouter />
            </div>
        );
    }
}