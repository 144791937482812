import React, { Fragment } from 'react';
import AvatarEditor from 'react-avatar-editor';
import Dropzone from 'react-dropzone';
import { StatefulComponent } from '../core/StatefulComponent';
import Add from '../static/add.png';
import styled from 'styled-components';
import { Messenger } from '../core/Messenger';
import { DefaultButton } from 'office-ui-fabric-react';
import { Axios } from '../core/axios';
import { SERVER_URI } from '../config';

const dataURItoBlob = (dataURI:string) => {
    let byteString = atob(dataURI.split(',')[1]);
    let ab = new ArrayBuffer(byteString.length);
    let ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ia], {
        type: 'image/jpeg'
    });
}

const errors = {
    default: 'Oops, něco se pokazilo',
    11: 'Nepodporovaný formát obrázku'
}


const AddImage = styled.img`
    cursor:pointer;
`;

type Props = {
    data?: string;
    size?: [number, number];
    canvasSize?: [number, number];
    uploadPath: string;
    name: string;
    field?:string;
};

type State = {
    image?: File | string;
    imgUrl?:string;
}
export class AvatarEdit extends StatefulComponent<Props, State>{
    state: State = {};
    inputRef = React.createRef<HTMLInputElement>();
    messenger = new Messenger(errors);
    editor?: AvatarEditor;
    updateData = (files: File[]) => {
        if (files.length !== 0) {
            this.updateStateValue('image', files[0]);
        } else {
            this.messenger.pushNotification('error', 11);
        }
    }
    setStyle() {
        const sizes = this.getSize();
        return { width: sizes[0] || 250, height: sizes[1] || 250 };
    }

    uploadFile = () => {
        const editor = this.editor;
        console.log(editor);
        if (editor) {
            const {uploadPath,name} = this.props;
            const img = dataURItoBlob(editor.getImage().toDataURL('image/jpeg'));
            const data = new FormData();
            data.append(name,img)
            Axios.post(uploadPath,data,{
                headers:{
                    'content-type': 'multipart/form-data'
                }
            }).then(({data})=>{
                this.updateState({
                    image:undefined,
                    imgUrl:data[this.props.field || 'baseImg']
                });
                this.messenger.pushNotification('success','Úvodní obrázek byl nahrán');
            }).catch(()=>{
                this.messenger.pushNotification('error','Oops. Něco se pokazilo. Prosím zkuste to znovu')
            })
        }
    }

    setEditor = (editor: AvatarEditor) => this.editor = editor;

    getSize(canvas?: boolean) {
        const { size, canvasSize } = this.props;
        if (canvas) {
            return canvasSize || [];
        } else {
            return size || [];
        }
    }
    selectImage = () => this.inputRef.current?.click();
    render() {
        const { data } = this.props,
            { image, imgUrl} = this.state;
        return <div>
            <Dropzone
                onDrop={this.updateData}
                noClick
                noKeyboard
                accept={['image/png', 'image/jpeg', 'image/gif']}
                multiple={false}
            >
                {({ getRootProps, getInputProps }) => (
                    <div {...getRootProps()}>
                        {(data || imgUrl) && !image && <AddImage src={SERVER_URI+(data || imgUrl)+'?v='+Date.now()} style={this.setStyle()} onClick={this.selectImage} alt="Uvodni obrazek" />}
                        {image && <Fragment>
                            <AvatarEditor
                                ref={this.setEditor}
                                width={this.getSize(true)[0] || 600}
                                height={this.getSize(true)[1] || 600}
                                image={image}
                                border={0}
                                style={this.setStyle()}
                            />
                            <br />
                            <DefaultButton text="Nahrát obrázek" onClick={this.selectImage} />
                            <DefaultButton text="Uložit" onClick={this.uploadFile} />
                        </Fragment>}
                        {!data && !image && !imgUrl && <AddImage width={this.getSize()[0] || 250} onClick={this.selectImage} src={Add} alt="Přidat obrázek" />}
                        <input {...getInputProps()} ref={this.inputRef} />
                    </div>
                )}
            </Dropzone>
        </div>;
    }
}