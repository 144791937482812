import React, { Component } from 'react';
import GR from 'react-recaptcha';
import { ReCaptcha } from 'react-recaptcha-v3';
import { FormikHelpers } from 'formik';
import { RECAPTCHA_KEY } from '../config';
import { GoogleReCaptcha } from 'react-google-recaptcha-v3';

type Props = {
    setFieldValue:FormikHelpers<any>['setFieldValue'];
    elementID:string;
}

export class Recaptcha extends Component<Props>{
    private recaptcha:React.RefObject<ReCaptcha> = React.createRef();
    verifyCallback = (recaptchaToken:any) => {
        console.log("recaptcha",recaptchaToken)
        this.props.setFieldValue("recaptcha",recaptchaToken);
    }

    updateToken = () => {
        // you will get a new token in verifyCallback
        //@ts-ignore
        this.recaptcha.execute();
    }
    render(){
        const {setFieldValue} = this.props;
        return <GoogleReCaptcha action="validate_captcha" onVerify={token => setFieldValue("recaptcha",token)} />
    }
}