import { loadTheme, createTheme} from 'office-ui-fabric-react';

loadTheme({
  palette: {
    themePrimary: '#219ECA'
  }
});

export const darkTheme = createTheme({
  palette: {
    themePrimary: '#fff',
    themeLighterAlt: '#fff',
    themeLighter: '#fff',
    themeLight: '#fff',
    themeTertiary: '#fff',
    themeSecondary: '#fff',
    themeDarkAlt: '#fff',
    themeDark: '#fff',
    themeDarker: '#fff',
    neutralLighterAlt: '#ffffff',
    neutralLighter: 'rgba(0,0,0,.2)',
    neutralLight: 'rgba(0,0,0,.1)',
    neutralQuaternaryAlt: '#ffffff',
    neutralQuaternary: '#ffffff',
    neutralTertiaryAlt: '#ffffff',
    neutralTertiary: '#fff',
    neutralSecondary: '#fff',
    neutralPrimaryAlt: '#fff',
    neutralPrimary: '#ffffff',
    neutralDark: '#f4f4f4',
    black: '#fff',
    white: '#ffffff',
  }});