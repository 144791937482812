import * as Yup from 'yup';

const createMultiSchema = <T>(schema:Yup.Schema<T>)=>{
    return Yup.object({
        cs:schema,
        en:schema,
        sk:schema
    })
}

export const ProjectSchema = Yup.object().shape({
    name:createMultiSchema(Yup.string().required()),
    description:createMultiSchema(Yup.string()),
    locationName:createMultiSchema(Yup.string().required()),
    gps:Yup.string().required(),
    time:Yup.number()
});