import React,{Component} from 'react';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { connect } from 'react-redux';
import { getAccountData } from '../../state/account/actions';
import {ThunkDispatch} from 'redux-thunk';


interface Props {}
class LoginInitBase extends Component<Props & {dispatch:ThunkDispatch<any,any,any>}> {
    componentDidMount(){
        const { dispatch } = this.props;
        dispatch(getAccountData)
    }
    render(){
        return <div className="loading display"><Spinner size={SpinnerSize.large} label="Ověřuji uživatele..." /></div>
    }
}
export const LoginInit = connect(null)(LoginInitBase);

