import React, { Fragment } from 'react';
import { ShimmeredDetailsList, IShimmeredDetailsListProps } from 'office-ui-fabric-react/lib/ShimmeredDetailsList';
import { SelectionMode, Selection, IObjectWithKey } from 'office-ui-fabric-react/lib/index';
import { IMarqueeSelectionProps } from 'office-ui-fabric-react/lib/MarqueeSelection';
import { CommandBar, ICommandBarItemProps } from 'office-ui-fabric-react/lib/CommandBar';
import { Toggle } from 'office-ui-fabric-react/lib/Toggle';
import { Dialog, DialogFooter } from 'office-ui-fabric-react/lib/Dialog';
import { PrimaryButton, DefaultButton } from 'office-ui-fabric-react/lib/Button';
import { Axios } from '../../core/axios';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { StatefulComponent } from '../../core/StatefulComponent';
import { IContextualMenuProps } from 'office-ui-fabric-react/lib/ContextualMenu';
import { IconButton } from 'office-ui-fabric-react';
import { Messenger } from '../../core/Messenger';

const getIDs = (field: any[]): string[] => {
    return field.reduce((buffer, value) => {
        const id: string | undefined = value['id'];
        if (id) {
            return [
                ...buffer,
                id
            ]
        } else {
            return buffer;
        }
    }, [] as string[])
}
interface Props extends RouteComponentProps {
    dataProps: IShimmeredDetailsListProps;
    selection: IMarqueeSelectionProps['selection'];
    dataLoaded?: boolean;
    deletePath: string;
    path: string;
}

interface State {
    selectable?: boolean;
    selection: IObjectWithKey[];
    showDialog: boolean;
    deleting: boolean;
    data: IShimmeredDetailsListProps['items'];
    actions?: boolean;
}
class DataTableBase extends StatefulComponent<Props, State> {
    messanger = new Messenger();
    state: State = {
        selectable: true,
        selection: [],
        showDialog: false,
        deleting: false,
        data: []
    };
    private delete = (id?: string) => {
        const selection: string[] = id ? [id] : getIDs(this.state.selection);
        if (selection.length !== 0) {
            this.updateState({ deleting: true, showDialog: false });
            Axios.delete(this.props.deletePath, {
                data: { payload: selection }
            }).then(() => this.deleteItems(selection))
                .catch(() => this.deleteItems())
        }
    }
    private getMenuProps(id: string): IContextualMenuProps {
        const { history, path } = this.props;
        return {
            items: [
                {
                    key: 'update',
                    text: 'Upravit',
                    iconProps: {
                        iconName: 'Edit'
                    },
                    onClick: () => history.push(path + '/update/' + id)
                },
                {
                    key: 'delete',
                    text: 'Smazat',
                    iconProps: {
                        iconName: 'Delete'
                    },
                    onClick: () => this.delete(id)
                }
            ]
        }
    };
    private enhanceData(items: { [key: string]: any }[]): any[] {
        return items.reduce((buffer, value) => {
            if (value.id) {
                value.actions = <IconButton menuProps={this.getMenuProps(value.id)} iconProps={{ iconName: 'More' }} title="Akce" ariaLabel="Akce" />;
                return [
                    ...buffer as [],
                    value
                ]
            } else {
                return buffer as [];
            }
        }, [] as any[]) as any[];
    }
    componentDidUpdate(prev: Props) {
        const { dataProps: { items } } = this.props;
        const { dataProps: { items: old } } = prev;
        if (items !== old) {
            this.updateState({ data: this.enhanceData(items) });
        }
    }
    private deleteItems(selection?: string[]) {
        let data: IObjectWithKey[] | undefined;
        if (selection) {
            data = this.state.data.reduce(
                (buffer, value) => selection.includes(value['id']) ?
                    buffer :
                    [
                        value,
                        ...buffer
                    ],
                [] as IObjectWithKey[]
            )
        }
        if (data) {
            this.setState({
                ...this.state,
                deleting: false,
                data
            });
        } else {
            this.setState({
                ...this.state,
                deleting: false,
            });
        }
        this.messanger.pushNotification('success','Objekt smazán');
    }
    private getCommands(): ICommandBarItemProps[] {
        const { history, path } = this.props;
        return [
            {
                key: 'new',
                text: 'Nový',
                iconProps: { iconName: 'Add' },
                onClick: () => history.push(path + '/new'),
                disabled: this.state.deleting
            },
            {
                key: 'upload',
                text: 'Smazat',
                iconProps: { iconName: 'Delete' },
                onClick: () => this._toggleDialog(),
                disabled: this.state.selection.length === 0 ? true : false,
            },
        ]
    };
    private selectable = (ev: React.MouseEvent<HTMLElement>, checked?: boolean) => {
        let selection = this.state.selection;
        if (!checked) {
            this._selection.setAllSelected(false);
            selection = [];
        }
        this.setState({
            ...this.state,
            selectable: checked,
            selection
        })

    }
    private _selection = new Selection({
        onSelectionChanged: () => {
            this.setState({
                selection: this._getSelectionDetails(),
            });
        },
    });
    render() {
        const { dataLoaded, dataProps: { items, ...dataProps } } = this.props;
        const { selectable, showDialog, deleting, data } = this.state;
        return <Fragment>
            <div style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'space-between'
            }}>
                <span style={{ margin: 12 }}>
                    <Toggle
                        offText="Výběr"
                        onText="Vypnout výběr"
                        onChange={this.selectable}
                        defaultChecked
                        disabled={deleting}
                    />
                </span>
                <CommandBar
                    items={[]}
                    farItems={this.getCommands()}
                    style={{ width: '50%' }}
                >
                </CommandBar>
            </div>
            <ShimmeredDetailsList
                setKey="items"
                selectionMode={selectable ? SelectionMode.multiple : SelectionMode.none}
                enableShimmer={deleting ? true : !dataLoaded}
                ariaLabelForShimmer="Content is being fetched"
                ariaLabelForGrid="Item details"
                listProps={{ renderedWindowsAhead: 0, renderedWindowsBehind: 0 }}
                selection={this._selection}
                items={data}
                onItemInvoked={this._onItemInvoked}
                {...dataProps}
            />
            <Dialog
                hidden={!showDialog}
                onDismiss={this._toggleDialog}
            >
                <DialogFooter>
                    <PrimaryButton onClick={() => this.delete()} text="Smazat" />
                    <DefaultButton onClick={this._toggleDialog} text="Zrušit" />
                </DialogFooter>
            </Dialog>
        </Fragment>
    }
    _onItemInvoked = (item: any) => {
        const { history, path } = this.props;
        history.push(path + '/update/' + item.id);
    }
    _toggleDialog = () => {
        this.setState({ ...this.state, showDialog: !this.state.showDialog })
    }

    private _getSelectionDetails() {
        return this._selection.getSelection();
    }
}

export const DataTable = withRouter(DataTableBase)