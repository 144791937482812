import React from 'react';
import { LoginSchema } from './login.schema';
import { onEnter } from '../../utils/keys';
import { Formik, Form, Field } from 'formik';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
import { PrimaryButton, Link } from 'office-ui-fabric-react';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import Logo from '../../static/logo.png';
import { ThunkDispatch } from 'redux-thunk';
import { login, Account } from '../../state/account/actions';
import { connect } from 'react-redux';
import { ReduxState } from '../../state/state';
import {Recaptcha} from '../Recaptcha';
import { StatefulComponent } from '../../core/StatefulComponent';



interface LoginData {
    username: string;
    password: string;
}
interface State {
    globalDisable?: boolean;
}

interface Props{
    dispatch:ThunkDispatch<any,any,any>;
    account:Account,
    callback:(data:any)=>any;
}
class LoginFormBase extends StatefulComponent<Props, State>{
    state: State = {};
    private login = (values: LoginData) => {
        this.updateStateValue('globalDisable',true);
        this.props.dispatch(login(values));
    }
    componentDidUpdate({account:next}:Readonly<Props>){
        const {account} = this.props;
        if(account !== next && !next.authenticated){
            this.updateStateValue('globalDisable',false);
        }
    }
    render() {
        const { globalDisable } = this.state;
        let { errors } = this.props.account;
        const {callback} = this.props;
        let error:string;
        if(typeof errors === 'string'){
            error = errors;
            errors = {};
        }
        return <div>
            <img className="logo" src={Logo} alt="Logo" />
            <Formik
                onSubmit={this.login}
                initialValues={{ username: '', password: '' }}
                validationSchema={LoginSchema}
            >
                {({
                    errors,
                    handleChange,
                    handleBlur,
                    submitForm,
                    setFieldValue,
                    values,
                    touched
                }) => (<div>
                    {globalDisable && <div className="loading"><Spinner size={SpinnerSize.large} label="Přihlašuji se..." /></div>}
                    <Form>
                        <Field
                            id="username"
                            disabled={globalDisable}
                            label="Uživatelské jméno"
                            name="username"
                            placeholder="Uživatelské jméno"
                            component={TextField}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onKeyDown={onEnter(submitForm)}
                            autoFocus
                            errorMessage={touched.username?errors.username:undefined}
                            value={values.username}
                        />
                        <Field
                            id="password"
                            type="password"
                            disabled={globalDisable}
                            label="Heslo"
                            name="password"
                            placeholder="Heslo"
                            component={TextField}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            onKeyDown={onEnter(submitForm)}
                            errorMessage={touched.password?errors.password:undefined}
                            value={values.password}
                        />
                        <br />
                        <Recaptcha setFieldValue={setFieldValue} elementID='login'/>
                        <PrimaryButton disabled={globalDisable} type="submit" text="Přihlásit se" style={{ marginTop: 15 }} />
                        <Link onClick={()=>callback('password')} style={{ paddingTop: 24, float: 'right', fontSize: '.8em' }}>Zapomenuté heslo</Link>
                        {error && <span className="error">{error}</span>}
                    </Form>
                </div>
                    )}
            </Formik>
        </div>
    }
}

const mapStateToProps = (state:ReduxState)=>({
    account:state.account
})
export const LoginForm = connect(mapStateToProps)(LoginFormBase);