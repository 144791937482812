import React, { FunctionComponent, Fragment } from 'react';
import { Separator } from 'office-ui-fabric-react/lib/Separator';
import styled from 'styled-components';

const HeaderBar = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center;
`;
const ButtonBar = styled.div`
    &>*{
        margin-left:10px;
    }
`;

type Props = {
    name:string;
}
export const Header:FunctionComponent<Props> = ({children,name})=>{
    return <Fragment>
        <HeaderBar>
            <h1>{name}</h1>
            <ButtonBar>
                {children}
            </ButtonBar>
        </HeaderBar>
        <Separator />
    </Fragment>
}