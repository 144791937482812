import { Axios } from '../../core/axios'
import { AxiosError } from 'axios';
import { ThunkAction } from 'redux-thunk';
export const ACCOUNT = 'ACCOUNT';

export type Account = {
    initialized?:boolean;
    authenticated?:boolean;
    name?: string;
    email?: string;
    errors?: UserData | string;
};

type UserData = Omit<Account, 'errors' | 'authenticated'>;

export type AccountAction = {
    type: typeof ACCOUNT;
    payload: Account
}

export const setAccount = (accountData: Account) => ({
    type: ACCOUNT,
    payload: accountData
});

type LoginData = {
    username: string;
    password: string;
}


export const logout:ThunkAction<any,any,any,any> = (dispatch)=>{
    Axios.get('/account/logout').finally(()=>{
    }).catch((e)=>{
    }).finally(()=>{
        dispatch(setAccount({
            authenticated:false,
            initialized:false
        }))
    })
    
}

/**
 * Signs in user
 * @param loginData 
 */
export const login = (loginData: LoginData):ThunkAction<any,any,any,any> => {
    return (dispatch) => {
        Axios.post('/account/login', loginData).then(({ data }) => {
            if (data) {
                const payload = data['payload'] as UserData;
                const status = data['status'];
                if (status) {
                    dispatch(
                        setAccount(
                            {
                                ...payload,
                                errors:undefined,
                                authenticated:true
                            }
                        )
                    );
                } else if (payload) {
                    dispatch(setAccount({
                        errors: payload,
                        authenticated:false,
                        initialized:true
                    }));
                } else {
                    dispatch(
                        setAccount(
                            { 
                                errors: 'Špatně vyplněné údaje' ,
                                authenticated:false,
                                initialized:true
                            }
                        )
                    )
                }
            }
        }).catch((e: AxiosError) => {
            switch (e.response?.status) {
                case 429:
                    dispatch(
                        setAccount({
                            errors:'Příliš mnoho pokusů. Zkuste to za 15 min znovu.',
                            authenticated:false,
                            initialized:true
                        })
                    );
                    break;
                case 401:
                    dispatch(
                        setAccount({
                            errors:'Neplatné přihlašovací údaje.',
                            authenticated:false,
                            initialized:true
                        })
                    );
                    break;
                default:
                    dispatch(
                        setAccount({
                            errors:'Oops. Něco je špatně. Prosím zkuste to později.',
                            authenticated:false,
                            initialized:true
                        })
                    );
                    break;
            }
        })
    }
}

export const getAccountData:ThunkAction<any,any,any,any> = (dispatch) =>{
    Axios.get('/account/validate').then(({data})=>{
        const payload = data as UserData;
        dispatch(setAccount({
            ...payload,
            authenticated:true
        }))
    }).catch((e:AxiosError)=>{
        dispatch(setAccount({initialized:true,authenticated:undefined}))
    })
}


export type AccountActions = AccountAction;

